import React from "react"
import { graphql, navigate } from "gatsby"
import {
  Container,
  Heading,
  Stack,
  Box,
  Grid,
  GridItem,
  Text,
  Divider,
  Button,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLocation } from "@reach/router"

const PropertySearchByPropertyCategories: React.FC<any> = ({ data }) => {
  const properties = data.allContentfulProperties.edges
  const location = useLocation()
  const buttonColor = "white"
  const buttonBgColor = "blackAlpha.800"
  const hoverColor = "white"
  const hoverBgColor = "blackAlpha.500"
  const areaTagColor = "white"
  const areaTagBgColor = "black"
  const newTagColor = "white"
  const newTagBgColor = "red.500"
  const kakakuHenkouTagColor = "red.500"
  const kakakuHenkouTagBgColor = "red.50"
  const shoudanTagColor = "cyan.500"
  const shoudanTagBgColor = "cyan.50"
  const seiyakuTagColor = "white"
  const seiyakuTagBgColor = "cyan.500"

  const isLand = location.pathname.includes("land")
  const isHouse = location.pathname.includes("house")

  properties.sort((a, b) => {
    if (a.node.status === 2 && b.node.status !== 2) return -1;
    if (a.node.status !== 2 && b.node.status === 2) return 1;

    if (a.node.status < b.node.status) return -1;
    if (a.node.status > b.node.status) return 1;

    return new Date(b.node.updatedAt) - new Date(a.node.updatedAt);
  });

  return (
    <Layout>
      <Seo title="物件情報" description="物件情報" />
      <Box h={`10vh`} backgroundSize="cover"></Box>
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack
          py={{ base: 8, lg: 12 }}
          spacing={{ base: 6, lg: 16 }}
          px={{ base: 4, lg: 12 }}
        >
          <Heading as={`h2`} fontSize={{ base: 18, lg: 24 }}>
            物件情報
          </Heading>
          <Stack direction={`row`} spacing={4}>
            <Button
              bg={buttonBgColor}
              color={buttonColor}
              rounded={0}
              p={2}
              isDisabled={isLand}
              fontSize={{ base: 13, lg: 15 }}
              _hover={{ bg: hoverBgColor, color: hoverColor }}
              onClick={() => navigate("/properties/property-categories/land")}
            >
              土地
            </Button>
            <Button
              bg={buttonBgColor}
              color={buttonColor}
              rounded={0}
              p={2}
              isDisabled={isHouse}
              fontSize={{ base: 13, lg: 15 }}
              _hover={{ bg: hoverBgColor, color: hoverColor }}
              onClick={() => navigate("/properties/property-categories/house")}
            >
              戸建
            </Button>
          </Stack>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={6}
          >
            {properties.length !== 0 &&
              properties.map(({ node: property }: any) => (
                <GridItem w="100%">
                  <LinkBox as="article">
                    <Stack spacing={3}>
                      <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)) ,url(${property.images[0]?.file?.url})`}
                        h={280}
                        backgroundSize="cover"
                        position="relative"
                      >
                        <Box
                          position={`absolute`}
                          bg={areaTagBgColor}
                          color={areaTagColor}
                          rounded={0}
                          p={1}
                          top={4}
                          left={4}
                        >
                          <Text fontSize={13}>{property.area.name}</Text>
                        </Box>
                        {property.status === 2 && (
                          <Box
                            position={`absolute`}
                            bg={newTagBgColor}
                            color={newTagColor}
                            rounded={0}
                            p={1}
                            top={4}
                            right={4}
                          >
                            <Text fontSize={13} fontWeight={`bold`}>
                              新着物件
                            </Text>
                          </Box>
                        )}
                        {property.status === 3 && (
                          <Box
                            position={`absolute`}
                            bg={kakakuHenkouTagBgColor}
                            color={kakakuHenkouTagColor}
                            rounded={0}
                            p={1}
                            top={4}
                            right={4}
                          >
                            <Text fontSize={13} fontWeight={`bold`}>
                              価格変更
                            </Text>
                          </Box>
                        )}
                        {property.status === 7 && (
                          <Box
                            position={`absolute`}
                            bg={shoudanTagBgColor}
                            color={shoudanTagColor}
                            rounded={0}
                            p={1}
                            top={4}
                            right={4}
                          >
                            <Text fontSize={13} fontWeight={`bold`}>
                              商談中
                            </Text>
                          </Box>
                        )}
                        {property.status === 8 && (
                          <Box
                            position={`absolute`}
                            bg={seiyakuTagBgColor}
                            color={seiyakuTagColor}
                            rounded={0}
                            p={1}
                            top={4}
                            right={4}
                          >
                            <Text fontSize={13} fontWeight={`bold`}>
                              成約済
                            </Text>
                          </Box>
                        )}
                        <Stack
                          position="absolute"
                          bottom={4}
                          right={4}
                          align="end"
                          spacing={0}
                        >
                          {property.status < 7 && (
                            <Text color={`white`} fontSize={24}>
                              {property.price} 万円
                            </Text>
                          )}
                          <Text color={`white`} fontSize={14}>
                            {property.propertyCategory.name}
                          </Text>
                        </Stack>
                      </Box>
                      <LinkOverlay href={`/properties/${property.slug}`}>
                        <Heading fontSize={{ base: 18, lg: 22 }}>
                          {property.propertyName}
                        </Heading>
                      </LinkOverlay>
                      <Text fontSize={{ base: 14, lg: 16 }}>
                        {property.adress}
                      </Text>
                    </Stack>
                    <Divider my={4} />
                    <Stack direction="row" spacing={8}>
                      {property.landSpaceSqm && (
                        <Stack align="center">
                          <StaticImage
                            src="../images/architecture-and-city.png"
                            alt="A dinosaur"
                            width={50}
                            height={50}
                            layout={`fixed`}
                          />
                          <Text fontWeight={`bold`} fontSize={12}>
                            {property.landSpaceSqm} ㎡
                          </Text>
                        </Stack>
                      )}
                      {property.bedroom && (
                        <Stack align="center">
                          <StaticImage
                            src="../images/bed.png"
                            alt="A dinosaur"
                            width={50}
                            height={50}
                            layout={`fixed`}
                          />
                          <Text fontWeight={`bold`} fontSize={12}>
                            {property.bedroom} Bedrooms
                          </Text>
                        </Stack>
                      )}
                      {property.bathroom && (
                        <Stack align="center">
                          <StaticImage
                            src="../images/bath.png"
                            alt="A dinosaur"
                            width={50}
                            height={50}
                            layout={`fixed`}
                          />
                          <Text fontWeight={`bold`} fontSize={12}>
                            {property.bathroom} Bathrooms
                          </Text>
                        </Stack>
                      )}
                    </Stack>
                  </LinkBox>
                </GridItem>
              ))}
          </Grid>
        </Stack>
      </Container>
    </Layout>
  )
}

export default PropertySearchByPropertyCategories

export const query = graphql`
  query ($propertyCategories: String!) {
    allContentfulPropertyCategories(
      filter: { slug: { eq: $propertyCategories } }
    ) {
      edges {
        node {
          name
          id
          slug
        }
      }
    }
    allContentfulProperties(
      filter: {
        status: { ne: 9 },
        propertyCategory: { slug: { eq: $propertyCategories } }
      }
      sort: { order: DESC, fields: updatedAt }
    ) {
      edges {
        node {
          id
          propertyCode
          propertyName
          slug
          propertyCategory {
            name
          }
          area {
            name
          }
          status
          price
          adress
          nearestStation
          transportation
          layout
          layoutDetail
          structure
          bedroom
          bathroom
          landSpaceSqm
          buildingSpaceSqm
          completion
          landOwnership
          landCategory
          cityPlanningArea
          useZoning
          otherLegalRestriction
          buildingCoverageRatio
          floorAreaRatio
          terrain
          adjacentRoad
          parking
          utilities
          occupancy
          handover
          conditionsTransactions
          images {
            id
            file {
              url
            }
          }
          tags {
            name
          }
          propertyLocation {
            lat
            lon
          }
          comment {
            id
            comment
          }
          onePoint {
            id
            onePoint
          }
          remark {
            id
            remark
          }
          pic
          createdAt
          updatedAt
        }
      }
    }
  }
`
